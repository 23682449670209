import React from "react"
import Table from "react-bootstrap/Table"
import { Container, Row, Col} from "react-bootstrap"
import { Link } from "gatsby"

import cx from 'classnames'
import * as styles from "../../styles/components/pricing.module.sass"

import { FaTree } from 'react-icons/fa';
import { RiMoneyPoundCircleLine } from 'react-icons/ri'
import { MdGroup } from 'react-icons/md'
import { TiCancel } from 'react-icons/ti'
import { AiTwotoneCalendar } from 'react-icons/ai'

/******************************************
 * Subheading section
 ******************************************/

const PricingInformationSubheading = () => {
    return (<Container>
        <Row className="justify-content-center">
            <Col xs="12" md="8" className={cx("text-center", styles.pricingInformationSubHeading, "py-2", "my-2")}>
                <h5>Prices are for 2 adults and include heating and linen</h5>
                <p>Please Note that there are additional charges below for additional guests.</p>
            </Col>
        </Row>
    </Container>);
}

/******************************************
 * Pricing data
 ******************************************/

const pricingInformation = [
    {
        timePeriodShortLabel: "Jan",
        timePeriodLabel: "January",
        threeNightPrice: 430,
        fourNightPrice: 500,
        fiveNightPrice: 550,
        sixNightPrice: 600,
        sevenNightPrice: 650,
        additionalNights: 100
    },
    {
        timePeriodShortLabel: "Feb - June",
        timePeriodLabel: "February, March, April, May, June",
        threeNightPrice: 430,
        fourNightPrice: 500,
        fiveNightPrice: 600,
        sixNightPrice: 700,
        sevenNightPrice: 800,
        additionalNights: 100
    },
    {
        timePeriodShortLabel: "Jul, Aug",
        timePeriodLabel: "July, August",
        threeNightPrice: undefined,
        fourNightPrice: undefined,
        fiveNightPrice: undefined,
        sixNightPrice: 800,
        sevenNightPrice: 900,
        additionalNights: 100
    },
    {
        timePeriodShortLabel: "Sept - Nov",
        timePeriodLabel: "September, October, November",
        threeNightPrice: 430,
        fourNightPrice: 500,
        fiveNightPrice: 600,
        sixNightPrice: 700,
        sevenNightPrice: 800,
        additionalNights: 100
    },
    {
        timePeriodShortLabel: "Dec",
        timePeriodLabel: "December",
        threeNightPrice: 430,
        fourNightPrice: 500,
        fiveNightPrice: 550,
        sixNightPrice: 600,
        sevenNightPrice: 650,
        additionalNights: 100
    },
    {
        timePeriodShortLabel: "Christmas",
        timePeriodLabel: "Christmas",
        threeNightPrice: undefined,
        fourNightPrice: undefined,
        fiveNightPrice: 700,
        sixNightPrice: 800,
        sevenNightPrice: 900,
        additionalNights: 100
    },
    {
        timePeriodShortLabel: "New Year",
        timePeriodLabel: "New Year",
        threeNightPrice: undefined,
        fourNightPrice: 550,
        fiveNightPrice: 650,
        sixNightPrice: 750,
        sevenNightPrice: 850,
        additionalNights: 100
    },
];

const toId = (str) => str.split(" ").join("_");

/******************************************
 * Pricing table
 ******************************************/

const PricingTable = ({ pricingData }) => {
    const rows = pricingData.map(dataPoint => (
        <tr key={"tr_" + toId(dataPoint.timePeriodLabel)}>
            <th>{dataPoint.timePeriodLabel}</th>
            <td>{dataPoint.threeNightPrice ? ("£" + dataPoint.threeNightPrice) : "Not available"} </td>
            <td>{dataPoint.fourNightPrice ? ("£" + dataPoint.fourNightPrice) : "Not available"} </td>
            <td>{dataPoint.fiveNightPrice ? ("£" + dataPoint.fiveNightPrice) : "Not available"} </td>
            <td>{dataPoint.sixNightPrice ? ("£" + dataPoint.sixNightPrice) : "Not available"} </td>
            <td>£{dataPoint.sevenNightPrice}</td>
            <td>£{dataPoint.additionalNights} per night</td>
        </tr>
    ));
    return (
        <Table responsive borderless hover style={{ tableLayout: "fixed" }} className={styles.pricingTable}>
            <thead>
                <tr>
                    <th>Price for 2 adults</th>
                    <th>3 nights<br />(Weekends only<br />Friday-Monday)</th>
                    <th>4 nights</th>
                    <th>5 nights</th>
                    <th>6 nights</th>
                    <th>7 nights</th>
                    <th>Additional nights</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </Table>
    )
};

/******************************************
 * Pricing shown by clicking month button
 ******************************************/
class PricingByMonthContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { clicked: this.toId(this.props.pricingData[0].timePeriodShortLabel) }
        this.showData = this.showData.bind(this);
    }

    showData(clickedButtonId) {
        this.setState((state) => ({
            show: !state.show,
            clicked: clickedButtonId
        }));
    }

    buildComponent() {
        const dataToShow = this.props.pricingData.filter(data => this.toId(data.timePeriodShortLabel) === this.state.clicked)[0];

        const row = (text, price) => (
            <Row style={{ fontSize: "14px" }}>
                <Col xs="8">
                    <p className={styles.pricingDataHeader}>{text}</p>
                </Col>
                <Col xs="4" className="text-center">
                    <p>£{price}</p>
                </Col>
            </Row>
        )

        return (<Container className={cx(styles.pricingByMonthData, styles.yellowBox, "py-2")}>
            {dataToShow.threeNightPrice && row("3 nights (Fri-Mon only)", dataToShow.threeNightPrice)}
            {dataToShow.fourNightPrice && row("4 nights", dataToShow.fourNightPrice)}
            {dataToShow.fiveNightPrice && row("5 nights", dataToShow.fiveNightPrice)}
            {dataToShow.sixNightPrice && row("6 nights", dataToShow.sixNightPrice)}
            {row("7 nights", dataToShow.sevenNightPrice)}
            {row("Additional nights", dataToShow.additionalNights)}
        </Container >)
    }

    toId(str) {
        return str.split(" ").join("_");
    }

    render() {
        const activeId = this.state.clicked;
        const buttons = this.props.pricingData.map(data => {
            const classNames = cx(activeId === this.toId(data.timePeriodShortLabel) ? styles.active : "");
            return (
                <Col xs="6" className={cx("my-1", "p-1", "text-center")}>
                    <button
                        id={this.toId(data.timePeriodShortLabel)}
                        onClick={e => this.showData(e.target.id)}
                        className={classNames}>
                        {data.timePeriodShortLabel}
                    </button>
                </Col>
            )
        });
        return (
            <div>
                <Container className={styles.pricingByMonthContainer}>
                    <Row>
                        <Col>
                            <p>Select a time period to see the latest pricing information</p>
                        </Col>
                    </Row>
                    <Row className={cx("justify-content-center", "align-items-center")}>
                        {buttons}
                    </Row>
                    <Row>
                        <Col xs="12">
                            {this.buildComponent(this.state.clicked)}
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

/******************************************
 * Pricing Text
 ******************************************/

const pricingText = [
    {
        icon: (<MdGroup />),
        header: "Additional Guests",
        text: [
            <p>Extra adults: +20% each<br />Children: +10% each</p>
        ]
    },
    {
        icon: (<RiMoneyPoundCircleLine />),
        header: "How to Pay",
        text: [
            <p>Preferably by cheque: make payable to V. Hallam and send to Devon Windmill Holidays, Yattors, Dainton, Nr Newton Abbot, Devon, TQ12 5TZ. Don’t forget to enclose your name, dates booked, email address and a mobile phone number that we can use to contact you on your journey and during your visit. (Please note, this is not the address of the windmill! – see <Link to="/location">Location page</Link>.)
            <br/>Alternatively, if necessary, by bank transfer: please phone Vince on <a href="tel:00447941313141">07941 313141</a> or <a href="tel:00441803472930">01803 472930</a> for details.</p>
        ]
    },
    {
        icon: (<AiTwotoneCalendar />),
        header: "Payment Schedule",
        text: [
            <p>20% non-returnable deposit to make bookings. Remainder 8 weeks before arrival. N.B. We don't send reminders.</p>
        ]
    },
    {
        icon: (<TiCancel />),
        header: "Cancellations",
        text: [
            <p>20% payable up until 8 weeks before booked date<br />50% if 8-4 weeks before<br />100% if within 28 days.</p>
        ]
    }
];

const PricingText = () => {
    const rows = pricingText.map((item, index) => {
        const mainCols = item.text.map(t => item.text.length === 1 ? (<Col>{t}</Col>) : (<Col md="4" sm="12">{t}</Col>));
        return (
            <Row className={cx(styles.pricingTextSection, index < pricingText.length - 1 && styles.bottomBorder)}>
                <Col md="1" sm="12">
                    <h4>{item.icon}</h4>
                </Col>
                <Col md="3" sm="12">
                    <h4>
                        {item.header}
                    </h4>
                </Col>
                {mainCols}
            </Row>
        )
    });

    return (
        <Container className={cx(styles.pricingText)} style={{ width: "90%" }}>
            {rows}
        </Container>
    );
}

const PricingSection = () => (
    <Container>
        <PricingInformationSubheading />
        <PricingByMonthContainer pricingData={pricingInformation} />
        <PricingTable pricingData={pricingInformation} />
        <PricingText />
    </Container>
)

export default PricingSection
