import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const PageDivider = () => (
    <Container>
        <Row className="justify-content-center">
            <Col sm="12" md="10" style={{borderTop: "3px solid white"}} className="my-4"></Col>
        </Row>
    </Container>
)

export default PageDivider