import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/pageTitle"

import PricingSection from "../components/pricingAndAvailability/pricingSection"
import Availability from "../components/pricingAndAvailability/availability"
import PageDivider from "../components/pageDivider"


const PricesAndAvailabilityPage = () => (
  <Layout pageInfo={{ pageName: "PricesAndAvailability" }}>
    <SEO title="PricesAndAvailability" />
    <PageTitle title="Availability" />
    <Availability />
    <PageDivider />
    <PageTitle title="Prices"/>
    <PricingSection />
  </Layout>
)

export default PricesAndAvailabilityPage
