// extracted by mini-css-extract-plugin
export var cyan = "pricing-module--cyan--17_YV";
export var cyanBg = "pricing-module--cyan-bg--1srRo";
export var cyanBo = "pricing-module--cyan-bo--19RvS";
export var androidGreen = "pricing-module--androidGreen--1rA5W";
export var androidGreenBg = "pricing-module--androidGreen-bg--hjAsb";
export var androidGreenBo = "pricing-module--androidGreen-bo--1igY_";
export var lavender = "pricing-module--lavender--1YLVG";
export var lavenderBg = "pricing-module--lavender-bg--2NQpi";
export var lavenderBo = "pricing-module--lavender-bo--iiaed";
export var corn = "pricing-module--corn--3H3w8";
export var cornBg = "pricing-module--corn-bg--1ZBlU";
export var cornBo = "pricing-module--corn-bo--1DdBR";
export var trns = "pricing-module--trns--A6knS";
export var pricingInformationSubHeading = "pricing-module--pricingInformationSubHeading--2RKON";
export var pricingByMonthContainer = "pricing-module--pricingByMonthContainer--1Ccb3";
export var active = "pricing-module--active--1w8PE";
export var pricingByMonthData = "pricing-module--pricingByMonthData--3K4VS";
export var pricingTable = "pricing-module--pricingTable--1GlZi";
export var pricingText = "pricing-module--pricingText--TL76L";
export var pricingTextSection = "pricing-module--pricingTextSection--1WpGf";
export var bottomBorder = "pricing-module--bottomBorder--1Trd3";