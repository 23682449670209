import React from 'react';
import Iframe from 'react-iframe';
import { Container, Row, Col } from "react-bootstrap"
import { FaPhone } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md'
import moment from 'moment'
import BookingCalendar from 'react-booking-calendar';

import { StaticQuery, graphql, Link } from 'gatsby';

import "../../styles/bookingCalendar.sass"

class Availability extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            calendar: null
        };
    }
    componentDidMount() {

        const query = graphql`
        {
          allMysqlBookings {
            edges {
              node {
                  the_date
              }
            }
          }
        }
        `

        setTimeout(() => {
            this.setState({

                calendar: (<StaticQuery
                    query={query}
                    render={data => {
                        const lastMonth = moment().subtract(1, 'months');
                        const bookedDates = data.allMysqlBookings.edges
                            .map(e => moment(e.node.the_date))
                            .filter(date => lastMonth.isBefore(date))
                            .map(m => m.toDate());

                        return (
                            <BookingCalendar bookings={bookedDates} disableHistory={true} />
                        )
                    }}
                />)
            });
        }, 2500);
    }

    render() {
        return (
            <Container>
                <Row className="justify-content-center">
                    <Col className="yellowBox text-center" md="9" sm="12">
                        <p>Calendar for availability information only - to make a reservation, please <Link to="/contact">contact us</Link>.</p>
                        <p><FaPhone className="align-middle" /> <a href="tel:00447941313141">07941 313141</a> or <a href="tel:00441803472930">01803 472930</a></p>
                        <p><MdEmail className="align-middle" /> <a href="mailto:enquiries@devonwindmills.co.uk">enquiries@devonwindmills.co.uk</a></p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col>
                        <Iframe url="http://old.devonwindmills.co.uk/calendar/ac-v3/index.php" width="800px" height="600px"/>
                    </Col>
                    {/*
                    <Col md="6" sm="12" className="calendar">
                        {this.state.calendar}
                    </Col>
                    */}
                </Row>
            </Container>
        )
    }
};

export default Availability
